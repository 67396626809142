import * as React from "react";
import Layout from "../components/layout/MainLayout";
import { graphql } from "gatsby";
import { Page } from "../classes/Page";
import ImageCopyBlock from "../components/RichText/Blocks/ImageCopy";
import { HyperLink } from "../classes/HyperLink";
import ContactMapWidget from "../components/one-off/ContactMapWidget";
import PartnerLogos from "../components/one-off/PartnerLogos";
import ServicesBlocks from "../components/one-off/Services/ServicesBlocks/ServicesBlocks";
import { HeroSlider } from "../components/one-off/HeroSlider/HeroSlider";
import EquipmentPicker from "../components/one-off/EquipmentPicker/EquipmentPicker";
import { Machines } from "../classes/Machine";
import { PageData } from "../node/page";
import { MetaDataQuery } from "../fragments/NodeSiteMetaFields";
import { MachinesQuery } from "../fragments/NodeMachinesFields";
import { WmkSeo } from "wmk-seo";
import { PageQuery } from "../fragments/NodePageFields";
import { RichTextReact } from "wmk-rich-text";
import { options } from "../components/RichText/richTextOptions";

const IndexPage = ({
  data
}: {
  data: {
    home: PageData;
    site: MetaDataQuery;
    machines: {
      edges: {
        node: MachinesQuery;
      }[];
    };
    contact: PageQuery;
  };
}) => {
  const homepage = new Page(data.home);
  const content = homepage.content;
  const contactPage = new Page(data.contact);
  const machines = new Machines(data.machines.edges);
  const metaData = data.site.meta;
  return (
    <Layout>
      <WmkSeo.Meta
        title={homepage.title}
        siteTitle={metaData.title}
        baseUrl={metaData.baseUrl}
        path={""}
        slug={"/"}
        description={metaData.description}
        twitterHandle={
          "twitterHandle" in metaData ? metaData.twitterHandle : null
        }
        ogImageUrl={homepage.image ? homepage.image.url("ogImage") : ""}
        twitterImageUrl={
          homepage.image ? homepage.image.url("twitterImage") : ""
        }
      />
      <HeroSlider />
      <EquipmentPicker machines={machines} />
      <ServicesBlocks />
      <RichTextReact content={content} options={options} />
      <ImageCopyBlock
        button={
          new HyperLink({
            __typename: "ContentfulLink",
            path: "/contact",
            text: "Get In Touch",
            target: undefined,
            linkTitle: "Contact"
          })
        }
        copy={contactPage.header.text}
        image={<ContactMapWidget bgImage={contactPage.image} />}
        imagePosition="First"
        headline="Contact Us"
      />

      <PartnerLogos />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    home: contentfulPage(title: { eq: "Home" }) {
      ...NodePageFields
      ...PageBlockImageCopy
    }
    contact: contentfulPage(title: { eq: "Contact" }) {
      ...NodePageFields
    }
    site {
      ...NodeSiteMetaFields
    }
    machines: allContentfulMachines {
      edges {
        node {
          ...NodeMachinesFields
        }
      }
    }
  }
`;
