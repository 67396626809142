import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Img } from "wmk-image";
import { Container, Col, Row } from "react-bootstrap";
import { colors } from "../ui/variables";
import { Typography } from "../ui/Typography";
import styled from "styled-components";
import { Fade } from "react-awesome-reveal";
import { ContentfulImageQuery, WmkImage } from "wmk-image";

const LogoCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 767px) {
    flex: 1 33%;
    max-width: 33%;
  }
`;

const LogoRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PartnerLogos = () => {
  const data: { logos: { images: ContentfulImageQuery[] } } = useStaticQuery(
    query
  );
  const logos = Array.isArray(data.logos.images) ? data.logos.images : [];
  return (
    <Container
      fluid
      style={{ background: colors.silver.hex, padding: `3rem 0` }}>
      <Container>
        <Row>
          <Col>
            <Typography.H2
              style={{ color: colors.blue.hex, padding: `0 0 1rem 0` }}>
              Our Partners
            </Typography.H2>
          </Col>
        </Row>
        {logos.length ? (
          <LogoRow>
            {logos.map((logo, i) => {
              const partnerImg = new Img(logo);
              return (
                <LogoCol
                  key={partnerImg.title + i}
                  style={
                    logo.title === "Kaitech primary-350 LOGO"
                      ? { paddingTop: "15px", paddingBottom: "15px" }
                      : {}
                  }
                  >
                  <Fade direction="up" delay={75 * i}>
                    <WmkImage image={partnerImg} />
                  </Fade>
                </LogoCol>
              );
            })}
          </LogoRow>
        ) : null}
      </Container>
    </Container>
  );
};

export default PartnerLogos;

const query = graphql`
  {
    logos: contentfulImageGallery(title: { eq: "Partner Logos" }) {
      images {
        title
        description
        gatsbyImageData
        file {
          url
          contentType
        }
      }
    }
  }
`;
