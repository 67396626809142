import * as React from "react";
import Slider from "react-slick";
import { useState, useEffect, useRef } from "react";
import EquipPreview from "../../ui/Equipment/EquipPreview";
import styled from "styled-components";
import EquipmentPickerDiv from "./EquipmentPickerDiv";
import { Typography } from "../../ui/Typography";
import { colors } from "../../ui/variables";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Fade } from "react-awesome-reveal";
import { Machines } from "../../../classes/Machine";
import { WmkLink } from "wmk-link";
import { Tag } from "../../../classes/Tag";
import { StyleObjectType } from "react-parallax";

const EquipmentPickerSection = styled.section`
  margin: 1rem;
  padding: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  button:first-child {
    padding-left: 0;
  }
  button {
    border: none;
    background: transparent;
    color: #c5c6c7;
    text-transform: uppercase;
  }
  .activeButton {
    color: ${colors.blue.hex};
    text-decoration: underline;
  }
`;

const TextContainer = styled.div`
  margin: 3rem 4rem 2rem 3rem;
  H3 {
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 2rem;
  }
  p {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 830px) {
    margin: 0 0 4rem 0;
    H3 {
      margin-bottom: 0;
    }
  }
`;

const LearnMoreContainer = styled.div`
  display: inline;
  span {
    display: inline-block;
    transition: all 0.3s ease;
  }
  p:hover > .secondBracket {
    transform: translate(-40%);
  }
  p:hover > .thirdBracket {
    transform: translate(-80%);
  }
`;
const ArrowWrap = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  width: 1.25rem !important;
  height: 1rem !important;
  color: ${colors.white.hex};
  cursor: pointer;
  z-index: 10;
  box-shadow: 0px 3px 6px #00000029;
  // &.slick-disabled {
  //   cursor: default;
  //   opacity: .5;
  // }
  &.slick-prev,
  &.slick-next {
    color: ${colors.white.hex};
    background: ${colors.white.hex};
    :before,
    :after {
      display: none;
    }
  }
  &.slick-prev {
    left: 3%;
    border-radius: 40% 0 0 40%;
  }
  &.slick-next {
    right: 3%;
    border-radius: 0 40% 40% 0;
  }
  &.slick-prev:hover,
  &.slick-prev:focus,
  &.slick-next:hover,
  &.slick-next:focus {
    color: ${colors.white.hex};
    background: ${colors.blue.hex};
    opacity: 0.8;
    transition: all 0.3s ease;
  }
  &.slick-prev:hover svg,
  &.slick-prev:focus svg,
  &.slick-next:hover svg,
  &.slick-next:focus svg{
    color: ${colors.white.hex}!important;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before{
    opacity: 1;
    color: white;
  @media only screen and (max-width: 767px) {
    &.slick-prev {
      left: 3%;
    }
    &.slick-next {
      right: 3%;
    }
  }
`;

const Arrow = (props: {
  onClick?: React.MouseEventHandler;
  prevNext: string;
  className?: string;
  style?: StyleObjectType;
}) => {
  const { onClick, prevNext, className, style } = props;
  return (
    <ArrowWrap onClick={onClick} style={{ ...style }} className={className}>
      {prevNext === "prev" ? (
        <FaChevronLeft color={colors.blue.hex} size=".8rem" />
      ) : (
        <FaChevronRight color={colors.blue.hex} size=".8rem" />
      )}
    </ArrowWrap>
  );
};

const EquipmentPicker = ({ machines }: { machines: Machines }) => {
  const hashKeys = Object.keys(machines.hash).sort();
  const [currentEquipmentCategory, setCurrentEquipmentCategory] = useState(
    Array.isArray(hashKeys) && hashKeys.length
      ? hashKeys[1]
      : "conveying-storing"
  );
  const [containerWidth, setContainerWidth] = useState(1201);
  const bracket = ">";
  const learnMoreString = "Learn More ";
  const learnMoreBrackets = (
    <p style={{ display: "inline-block" }}>
      {learnMoreString}
      <span>{bracket}</span>
      <span className={"secondBracket"}>{bracket}</span>
      <span className={"thirdBracket"}>{bracket}</span>
    </p>
  );
  const containerRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      const box = containerRef.current.getBoundingClientRect();
      setContainerWidth(box.width);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setContainerWidth]);

  const currentCategoryMachines = machines.hash[`${currentEquipmentCategory}`];
  const typeButtons =
    Array.isArray(hashKeys) && hashKeys.length
      ? hashKeys.map((key, i) => {
          const machinesByCategory = machines.hash[key];
          let finalTag: Tag | null = null;
          machinesByCategory.forEach((machine) => {
            const filteredTag = machine.category.find((tag) => {
              return tag.slug === key;
            });
            finalTag = filteredTag;
          });

          return containerWidth > 1200 ? (
            <button
              key={i + finalTag.name}
              id={finalTag.slug}
              className={
                currentEquipmentCategory === finalTag.slug
                  ? "activeButton"
                  : "inactiveButton"
              }
              onClick={() => setCurrentEquipmentCategory(finalTag.slug)}>
              <Typography.P style={{ fontSize: `.875rem` }}>
                {finalTag.name}
              </Typography.P>
            </button>
          ) : (
            <option value={finalTag.slug} key={i + finalTag.name}>
              {finalTag.name}
            </option>
          );
        })
      : null;

  const slidesToShow =
    currentCategoryMachines.length > 1
      ? containerWidth > 950
        ? 3 > currentCategoryMachines.length - 1
          ? currentCategoryMachines.length - 0.1
          : 3.5
        : containerWidth > 740
        ? 2 > currentCategoryMachines.length - 1
          ? currentCategoryMachines.length - 0.1
          : 2.5
        : 1
      : 1;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    arrows: true,
    centerMode: true,
    adaptiveHeight: false,
    prevArrow: <Arrow prevNext="prev" />,
    nextArrow: <Arrow prevNext="next" />
  };

  const equipmentCarouselData =
    Array.isArray(currentCategoryMachines) && currentCategoryMachines.length ? (
      <Slider {...settings}>
        {currentCategoryMachines.map((machine, i) => {
          return machine ? (
            <div
              style={{ height: "100%" }}
              key={i + machine.displayTitle + "container"}>
              <Fade direction="up" delay={500 * i} triggerOnce>
                <EquipPreview
                  key={i + machine.displayTitle}
                  title={machine.displayTitle}
                  apps={machine.applications}
                  image={machine.thumbnails[0]}
                  link={machine.link}
                />
              </Fade>
            </div>
          ) : null;
        })}
      </Slider>
    ) : null;

  const equipmentCarousel =
    Array.isArray(currentCategoryMachines) && currentCategoryMachines.length ? (
      <EquipmentPickerDiv
        slidesToShow={slidesToShow}
        data={equipmentCarouselData}
      />
    ) : null;

  const typeButtonDisplay =
    containerWidth < 1200 ? (
      <select
        name="machineCategories"
        onChange={(e) => setCurrentEquipmentCategory(e.target.value)}>
        {typeButtons}
      </select>
    ) : (
      typeButtons
    );

  return (
    <EquipmentPickerSection ref={containerRef}>
      <TextContainer>
        <Typography.H3 style={{ color: colors.blue.hex }}>
          Machines & Solutions
        </Typography.H3>
        <WmkLink to="/machines-and-solutions">
          <LearnMoreContainer>{learnMoreBrackets}</LearnMoreContainer>
        </WmkLink>
        <ButtonContainer>{typeButtonDisplay}</ButtonContainer>
      </TextContainer>
      {equipmentCarousel ? equipmentCarousel : <></>}
    </EquipmentPickerSection>
  );
};

export default EquipmentPicker;
