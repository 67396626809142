import * as React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import ServicesBlock from "./ServicesBlock";
import { MenuFields } from "../../../../fragments/NodeMenuFields";
import { PageQuery } from "../../../../fragments/NodePageFields";

const Wrap = styled.div``;

const ServicesBlocks = () => {
  const data: {
    contentfulPage: { featuredLinks: MenuFields[] };
  } = useStaticQuery(query);
  const homeFeatLinks = Array.isArray(data.contentfulPage.featuredLinks)
    ? data.contentfulPage.featuredLinks
    : [];
  const servicePages = homeFeatLinks.filter((featLink) => {
    return (
      featLink.menuTitle &&
      featLink.menuTitle.toLowerCase().includes("services")
    );
  })[0].links;
  return (
    <Wrap>
      <Container fluid>
        <Row>
          {servicePages.map((service, i) => {
            return (
              <Col
                lg={6}
                style={{ paddingLeft: 0, paddingRight: 0 }}
                key={service.pageTitle + i}>
                <ServicesBlock service={service as PageQuery} />
              </Col>
            );
          })}
        </Row>
      </Container>
    </Wrap>
  );
};

export default ServicesBlocks;

const query = graphql`
  query {
    contentfulPage(title: { eq: "Home" }) {
      featuredLinks {
        ... on ContentfulMenu {
          ...NodeMenuFields
        }
      }
    }
  }
`;
