import styled from "styled-components";
import { colors } from "../../ui/variables";
import get from "lodash/get";
import * as React from "react";

const EquipmentPickerWrapper = styled.div`
  @charset 'UTF-8';
  /* Slider */
  .slick-loading .slick-list {
    background: #fff url("./ajax-loader.gif") center center no-repeat;
  }
  //   @media only screen and (min-width: 740px) {
  // .slick-list {
  //   width: 100%;
  //   -webkit-mask: linear-gradient(
  //     to left,
  //     transparent 0%,
  //     #fff 15%,
  //     #fff 85%,
  //     transparent 100%
  //   );
  //   mask: linear-gradient(
  //     to left,
  //     transparent 0%,
  //     #fff 20%,
  //     #fff 80%,
  //     transparent 100%
  //   );
  // }
  //   }
  //   @media only screen and (max-width: 741px) {
  //     .slick-list {
  //       width: 100%;
  //       -webkit-mask: none;
  //       mask: none;
  //     }
  //   }
  .slick-slide {
    height: 100%;
    & > div {
      ${({ slidesToShow }) => slidesToShow < 3.5 ? 'max-width: 22vw;' : ''}
    }
  }
  /* Icons */
  @font-face {
    font-family: "slick";
    font-weight: normal;
    font-style: normal;
    src: url("./fonts/slick.eot");
    src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"),
      url("./fonts/slick.woff") format("woff"),
      url("./fonts/slick.ttf") format("truetype"),
      url("./fonts/slick.svg#slick") format("svg");
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 2.4rem;
    height: 2rem;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: black;
    border: solid 1px ${get(colors, "blue.hex")};
    outline: ${get(colors, "blue.hex")};
    background: white;
    box-shadow: 0px 3px 6px #00000029;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: white;
    outline: ${get(colors, "blue.hex")};
    background: ${get(colors, "blue.hex")};
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
    color: white;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 2rem;
    line-height: 0;
    opacity: 0.75;
    color: ${get(colors, "blue.hex")};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev {
    left: 10%;
    top: 0;
    border-radius: 1rem 0 0 1rem;
    z-index: 1;
  }
  [dir="rtl"] .slick-prev {
    right: 10%;
    top: 0;
  }
  .slick-prev:before {
    content: "<";
  }
  [dir="rtl"] .slick-prev:before {
    content: ">";
  }
  .slick-next {
    right: 10%;
    top: 0;
    border-radius: 0 1rem 1rem 0;
  }
  [dir="rtl"] .slick-next {
    left: 10%;
    top: 0;
  }
  .slick-next:before {
    content: ">";
  }
  [dir="rtl"] .slick-next:before {
    content: "<";
  }

  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    position: absolute;
    bottom: -25px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
  }
  @media only screen and (max-width: 767px) {
    .slick-slide {
      & > div {
        ${({ slidesToShow }) => slidesToShow < 3.5 ? 'max-width: 90%;' : ''}
      }
    }
  }
`;

const TransparencyWrapper = styled.div`
  .slick-list {
    width: 100%;
    -webkit-mask: linear-gradient(
      to left,
      transparent 0%,
      #fff 15%,
      #fff 85%,
      transparent 100%
    );
    mask: linear-gradient(
      to left,
      transparent 0%,
      #fff 20%,
      #fff 80%,
      transparent 100%
    );
  }
`;

const EquipmentPickerDiv = ({ slidesToShow, data }) => {
  return (
    <EquipmentPickerWrapper slidesToShow={slidesToShow}>
      {slidesToShow > 1 && <TransparencyWrapper>{data}</TransparencyWrapper>}
      {slidesToShow <= 1 && <>{data}</>}
    </EquipmentPickerWrapper>
  );
};

export default EquipmentPickerDiv;
