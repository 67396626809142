import * as React from "react";
import styled from "styled-components";
import { WmkImage } from "wmk-image";
import { WmkLink } from "wmk-link";
import { Typography } from "../../ui/Typography";
import { colors } from "../../ui/variables";
import { HeroSlideData } from "./HeroSlider";

const Wrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const BgWrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;
const DarkenOverlay = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  @media only screen and (max-width: 991px) {
    background: rgba(0, 0, 0, 0.5);
  }
`;
const TitleAndLinkWrap = styled.div`
  position: relative;
  // width: 50rem;
  width: fit-content;
  max-width: 70vw;
  height: 100%;
  text-align: center;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  padding-top: 15rem;
  * {
    color: ${colors.white.hex};
  }
  a {
    display: inline-block !important;
    margin-top: 1rem;
    text-decoration: none;
    :hover {
      p {
        background: linear-gradient(to bottom, #2a7de1, #2367ba) !important;
      }
    }
  }
  h1 {
    margin-bottom: 1rem;
  }
  @media only screen and (min-width: 1586px) {
    // width: 48rem !important;
    h1 {
      font-size: 4rem !important;
    }
  }
  @media only screen and (min-width: 1600px) {
    h1 {
      font-size: 4rem !important;
    }
  }
  @media only screen and (max-width: 1150px) {
    h1 {
      font-size: 50px !important;
    }
  }
  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 24px !important;
    }
    max-width: 80vw;
  }
  @media only screen and (max-width: 400px) {
    h1 {
      font-size: 30px !important;
    }
  }
`;

const TextBackground = styled.div`
  background: rgb(
    ${colors.darkBlue.r},
    ${colors.darkBlue.g},
    ${colors.darkBlue.b},
    0.6
  );
  // width: fit-content;
  height: fit-content !important;
  padding: 0 1rem;
`;

const HeroSlide = ({ slide }: { slide: HeroSlideData }) => {
  const image = slide.image;
  const title = slide.title;
  const subheader = slide.subheader;
  const path = slide.path;
  const buttonText = slide.buttonText;

  const finalPath =
    path && path.includes("https://pepperequipmentco.com/")
      ? path.replace("https://pepperequipmentco.com/", "")
      : path.charAt(0) === "/"
      ? path.split("/").slice(1, path.length).join("/")
      : path;

  const getDisplay = () => {
    return (
      <>
        {title && (
          <TextBackground>
            <Typography.H1 style={{ fontWeight: 500 }}>{title}</Typography.H1>
          </TextBackground>
        )}
        {subheader && (
          <TextBackground style={{ height: "fit-content" }}>
            <Typography.H3 style={{ fontWeight: 300 }}>
              {subheader}
            </Typography.H3>
          </TextBackground>
        )}
        {path && (
          <WmkLink
            to={`/${finalPath}`}
            style={subheader ? { marginTop: "2rem" } : {}}>
            <Typography.Button
              style={{
                padding: "5px 15px",
                background: colors.blue.hex,
                marginBottom: 0,
                textTransform: "uppercase"
              }}>
              {buttonText ? buttonText : "LEARN MORE"}
            </Typography.Button>
          </WmkLink>
        )}
      </>
    );
  };

  return (
    <Wrap>
      <BgWrap>
        <WmkImage image={image} />
        <DarkenOverlay />
      </BgWrap>
      <TitleAndLinkWrap>{getDisplay()}</TitleAndLinkWrap>
    </Wrap>
  );
};

export default HeroSlide;
