import * as React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { Typography } from "../../../ui/Typography";
import { WmkLink } from "wmk-link";
import { colors } from "../../../ui/variables";
import { PageQuery } from "../../../../fragments/NodePageFields";

const Wrap = styled.div`
  position: relative;
  height: 30vw;
  max-height: 30rem;
  min-height: 18rem;
`;
const ImgWrap = styled.div`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;
const DarkenOverlay = styled.div`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  @media only screen and (max-width: 991px) {
    background: rgba(0, 0, 0, 0.6);
  }
`;
const ContentWrap = styled.div`
  position: relative;
  z-index: 2;
  top: 45%;
  left: 0;
  padding: 0 10%;
  * {
    color: ${colors.white.hex};
  }
  .description {
    font-weight: 300 !important;
  }
  a {
    display: inline-block !important;
    margin-top: 1rem;
    text-decoration: none;
    :hover {
      p {
        background: linear-gradient(to bottom, #2a7de1, #2367ba) !important;
      }
    }
  }
  @media only screen and (max-width: 1399px) {
    top: 35%;
  }
  @media only screen and (max-width: 1199px) {
    top: 20%;
  }
  @media only screen and (max-width: 991px) {
    top: 0;
    height: 100%;
    padding: 7%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .description {
      font-size: 16px !important;
      line-height: 20px;
    }
    a {
      width: 8.1rem;
    }
  }
`;

const ServicesBlock = ({ service }: { service: PageQuery }) => {
  const image = service.featuredImage;
  const gatsby = image.gatsbyImageData;
  const alt = image.title;
  const title = service.pageTitle;
  const description = service.header.text;
  const slug = service.slug;
  const parentPath = service.parentPath;
  const fullPath =
    parentPath && slug
      ? `${parentPath !== "/" ? parentPath : ""}/${slug}`
      : slug
      ? `/${slug}`
      : `#`;

  return (
    <Wrap>
      <ImgWrap>
        <GatsbyImage image={gatsby} alt={alt} />
        <DarkenOverlay />
      </ImgWrap>
      <ContentWrap>
        <Typography.H4>{title}</Typography.H4>
        <Typography.P
          style={{ fontWeight: 300 }} // doesn't change anything - seems like the font weight can't be adjusted for Typography.P
          className="description">
          {description}
        </Typography.P>
        <WmkLink to={fullPath}>
          <Typography.Button
            style={{
              padding: "5px 15px",
              background: colors.blue.hex,
              marginBottom: 0
            }}>
            LEARN MORE
          </Typography.Button>
        </WmkLink>
      </ContentWrap>
    </Wrap>
  );
};

export default ServicesBlock;
