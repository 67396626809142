import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import HeroSlide from "./HeroSlide";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { colors } from "../../ui/variables";
import { graphql, useStaticQuery } from "gatsby";
import { Img } from "wmk-image";
import { SlideGalleryQuery } from "../../../fragments/NodeSlideFields";

//contents of carousel-theme is in this styled component
export const CarouselThemeWrap = styled.div`
  overflow-x: hidden;
  @charset 'UTF-8';
  /* Slider */
  .slick-loading .slick-list {
    background: #fff
      url("../../../../node_modules/slick-carousel/slick/ajax-loader.gif")
      center center no-repeat;
  }
  /* Icons */
  @font-face {
    font-family: "slick";
    font-weight: normal;
    font-style: normal;
    src: url("../../../../node_modules/slick-carousel/slick/fonts/slick.eot");
    src: url("../../../../node_modules/slick-carousel/slick/fonts/slick.eot?#iefix")
        format("embedded-opentype"),
      url("../../../../node_modules/slick-carousel/slick/fonts/slick.woff")
        format("woff"),
      url("../../../../node_modules/slick-carousel/slick/fonts/slick.ttf")
        format("truetype"),
      url("../../../../node_modules/slick-carousel/slick/fonts/slick.svg#slick")
        format("svg");
  }
  /* Arrows */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover,
  .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before {
    opacity: 1;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25;
  }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    opacity: 0.75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-prev {
    left: -25px;
  }
  [dir="rtl"] .slick-prev {
    right: -25px;
    left: auto;
  }
  .slick-prev:before {
    content: "←";
  }
  [dir="rtl"] .slick-prev:before {
    content: "→";
  }
  .slick-next {
    right: -25px;
  }
  [dir="rtl"] .slick-next {
    right: auto;
    left: -25px;
  }
  .slick-next:before {
    content: "→";
  }
  [dir="rtl"] .slick-next:before {
    content: "←";
  }
  /* Dots */
  .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }
  .slick-dots {
    position: absolute;
    z-index: 10;
    bottom: 2rem;
    text-align: center;
    padding: 0;
    display: block;
    width: 100%;
    margin: 0;
    list-style: none;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  .slick-dots li button:hover,
  .slick-dots li button:focus {
    outline: none;
  }
  .slick-dots li button:hover:before,
  .slick-dots li button:focus:before {
    opacity: 1;
  }
  .slick-dots li button:before {
    font-family: "slick";
    font-size: 2.2rem;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.8;
    color: #c0c4c7;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #2a7de1;
  }
  width: 100%;
  height: 40rem;
  .slick-slider,
  .slick-list,
  .slick-track,
  .slick-slide,
  div {
    height: 100%;
  }
  .slick-current {
    z-index: 1;
  }
`;
const ArrowWrap = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 10;
  width: 1.25rem !important;
  height: 1rem !important;
  color: ${colors.white.hex};
  cursor: pointer;
  z-index: 10;
  box-shadow: 0px 3px 6px #00000029;
  // &.slick-disabled {
  //   cursor: default;
  //   opacity: .5;
  // }
  &.slick-prev,
  &.slick-next {
    color: ${colors.white.hex};
    background: ${colors.white.hex};
    :before,
    :after {
      display: none;
    }
  }
  &.slick-prev {
    left: 10%;
    border-radius: 40% 0 0 40%;
  }
  &.slick-next {
    right: 10%;
    border-radius: 0 40% 40% 0;
  }
  &.slick-prev:hover,
  &.slick-prev:focus,
  &.slick-next:hover,
  &.slick-next:focus {
    color: ${colors.white.hex};
    background: ${colors.blue.hex};
    opacity: 0.8;
    transition: all 0.3s ease;
  }
  &.slick-prev:hover svg,
  &.slick-prev:focus svg,
  &.slick-next:hover svg,
  &.slick-next:focus svg{
    color: ${colors.white.hex}!important;
  }
  .slick-prev:hover:before,
  .slick-prev:focus:before,
  .slick-next:hover:before,
  .slick-next:focus:before{
    opacity: 1;
    color: white;
  }
  @media only screen and (max-width: 767px) {
    &.slick-prev {
      left: 1% !important;
    }
    &.slick-next {
      right: 1% !important;
    }
`;
export const Arrow = ({
  onClick,
  prevNext,
  className,
  style
}: {
  onClick: React.MouseEventHandler;
  prevNext: "prev" | "next";
  className: string;
  style: React.CSSProperties;
}) => {
  return (
    <ArrowWrap onClick={onClick} style={{ ...style }} className={className}>
      {prevNext === "prev" ? (
        <FaChevronLeft color={colors.blue.hex} size=".8rem" />
      ) : (
        <FaChevronRight color={colors.blue.hex} size=".8rem" />
      )}
    </ArrowWrap>
  );
};

export interface HeroSlideData {
  image: Img;
  path: string;
  subheader: string;
  title: string;
  buttonText: string;
}

export const HeroSlider = () => {
  let [sliderSpeed, setSliderSpeed] = useState(5800);
  const queryData: {
    gallery: SlideGalleryQuery;
  } = useStaticQuery(sliderGalleryQuery);
  const query = queryData.gallery.slides;
  const data = query.map((q) => {
    return {
      image: new Img(q.image),
      path: q.path,
      title: q.title,
      subheader: q.subheader,
      buttonText: q.buttonText
    };
  });
  const settings = {
    dots: true,
    arrows: true,
    fade: true,
    slidesToShow: 1,
    prevArrow: <Arrow prevNext="prev" />,
    nextArrow: <Arrow prevNext="next" />,
    autoplay: true,
    infinte: true,
    speed: sliderSpeed,
    pauseOnHover: true
  };

  return (
    <CarouselThemeWrap
      onMouseEnter={() => setSliderSpeed(500)}
      onMouseLeave={() => setSliderSpeed(5800)}>
      <Slider {...settings}>
        {data.map((slide, i) => (
          <HeroSlide slide={slide} key={`hero-slide-${i}`} />
        ))}
      </Slider>
    </CarouselThemeWrap>
  );
};

const sliderGalleryQuery = graphql`
  {
    gallery: contentfulSlideGallery(title: { eq: "Home Slides" }) {
      ...NodeSlideGalleryFields
    }
  }
`;
