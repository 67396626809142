import * as React from "react";
import GoogleMapReact from "google-map-react";
import { MapCard, mapDefaults } from "./ContactPage/ContactPage";
import { Img, WmkImage } from "wmk-image";

const ContactMapWidget = ({ bgImage }: { bgImage: Img }) => {
  return (
    <div style={{ positon: "relative" }}>
      <WmkImage image={bgImage} />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "80%",
          width: "90%",
          margin: "5%"
        }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapDefaults.key }}
          defaultCenter={mapDefaults.center}
          defaultZoom={mapDefaults.zoom}>
          <MapCard
            smaller
            lat={mapDefaults.center.lat}
            lng={mapDefaults.center.lng}
          />
        </GoogleMapReact>
      </div>
    </div>
  );
};

export default ContactMapWidget;
